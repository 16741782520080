import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import Icon from '@/components/icon/icon'
import CartItem from '@/components/cart/cart-item'
import CountryCurrencySelect from '@/components/cart/country-currency-select'
import { SpinnerComponent } from '@/components/common/SpinnerComponent'
import styleConfig from '@/styles/style-config'
import { formatPrice } from '@/lib/helpers'
import { getCartCheckoutRedirectUrl, updateAllCartItems } from '@/redux/actions/cartAction'
import { cartView } from '@/lib/intellisuggest'
import { updateCartModal } from '@/redux/actions/uiAction'
import { useDispatch } from 'react-redux'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { trans } from '@/lib/locale-helper'
import SignInLink from '@/components/common/sign-in-link'
import nextConfig from 'next/config'
import {
  getRegion,
  setCartLocale,
  selectShippingRegions,
} from '@/lib/region-helper'
import regions from '@/config/regions'
import { getShippingPrice } from '@/lib/shipping-helper'
import { useToasts } from 'react-toast-notifications'
import ErrorItemsNode from '@/components/common/error-items-node'
import { prepareCartForCheckout } from '@/lib/product-helper'
import { CartClient } from '@/lib/cart-client'

const CartView = ({ isOpen }) => {
  const {
    id: cartId,
    line_items,
    base_amount,
    cart_amount,
    all_items_in_stock,
    all_cart_items,
    currencySymbol,
    cart_loading,
    removed_items,
  } = useSelector((state) => state.cartReducer)
  const cartData = useSelector((state) => state.cartReducer)
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [device, setDevice] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState({})
  const [openCountrySelect, setOpenCountrySelect] = useState(false)
  const router = useRouter()
  const dispatch = useDispatch()
  const [isCommingSoonProductExist, setCommingSoonProductExist] = useState(false)
  const [isRetiredProductExist, setRetiredProductExist] = useState(false)
  const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const [shippingPrice, setShippingPrice] = useState(null)

  const { publicRuntimeConfig } = nextConfig()
  const { isAuthenticated, profile } = useSelector((state) => state.userReducer)
  let customerId = 0
  let skuArr = []
  if (line_items && line_items.physical_items.length > 0) {
    line_items.physical_items.map((item) => {
      skuArr.push(item.product.sku)
    })
  }
  if (isAuthenticated) {
    customerId = profile.bigCommCustomerID
  }
  useEffect(() => {
    
    if (isOpen && line_items && line_items.physical_items.length > 0) {
      cartView(skuArr, line_items, customerId) 
      isCommingSoonItem()
      isRetiredProduct()
    }

    //in case if we are removing all line items then need to update the ssCartProducts from cookies as well
    if (isOpen && line_items && line_items?.physical_items.length === 0) {
      cartView(skuArr, line_items, customerId)
    }
    
  }, [isOpen, line_items])

  useEffect(() => {
    const checkDevice = navigator.userAgent.includes('iPhone')
    if (checkDevice) {
      setDevice(true)
    }
  })

  useEffect(() => {
    ;(async () => {
      await dispatch(
        updateAllCartItems(all_cart_items.filter((cartItem) => cartItem.added_to_wishlist == false))
      )
    })()
  }, [])

  const isCommingSoonItem = () => {
    for (let index = 0; index < line_items?.physical_items.length; index++) {
      let isCommingSoon =
        line_items?.physical_items[index] &&
        line_items?.physical_items[index].product &&
        line_items?.physical_items[index].product.custom_fields
          ? line_items?.physical_items[index].product.custom_fields.find(
              (op) => op.name.toLowerCase() === 'is_coming_soon' && op.value === 'true'
            )
          : null
      if (isCommingSoon && isCommingSoon.id) {
        setCommingSoonProductExist(true)
        break
      } else {
        setCommingSoonProductExist(false)
      }
    }
  }

  const isRetiredProduct = () => {
    for (let index = 0; index < line_items?.physical_items.length; index++) {
      let isRetired =
        line_items?.physical_items[index] &&
        line_items?.physical_items[index].product &&
        line_items?.physical_items[index].product.custom_fields
          ? line_items?.physical_items[index].product.custom_fields.find(
              (op) => op.name.toLowerCase() === 'is_retired' && op.value === 'true'
            )
          : null
      if (isRetired && isRetired.id) {
        setRetiredProductExist(true)
        break
      } else {
        setRetiredProductExist(false)
      }
    }
  }

  useEffect(() => {
    let country = getRegion(region.domain)
    let savedCountry = localStorage?.getItem('user_checkout_locale')

    if (savedCountry) {
      savedCountry = JSON.parse(savedCountry)
      setSelectedCountry(savedCountry)
    } else {
      setSelectedCountry(country)
      setCartLocale(country)
    }

    document.addEventListener('mousedown', handleCountrySelectorClick)
    return () => {
      document.removeEventListener('mousedown', handleCountrySelectorClick)
    }
  }, [])

  const handleCountrySelectorClick = (e) => {
    const parent = e.target.parentElement
    const elem = e.target
    let btn = null
    let content = null
    if (parent == null) {
      return
    }

    if (elem.classList.contains('country-selector-btn')) {
      btn = elem
    } else if (parent.classList.contains('country-selector-btn')) {
      btn = parent
    }
    if (btn != null) {
      if (btn.classList.contains('active') == true) {
        setOpenCountrySelect(true)
      } else {
        setOpenCountrySelect(false)
      }
    } else {
      if (elem.classList.contains('country-selector-content')) {
        content = elem
      } else if (parent.classList.contains('country-selector-content')) {
        content = parent
      }

      if (content == null) {
        setOpenCountrySelect(false)
      }
    }
  }
  
  const openCheckoutPage = async () => {
    try {
      // const { itemList: finalItemList } = await prepareCartForCheckout(cartData)
      // const { data: cartMetafields } = await CartClient.get('/cartMetafields')
      // if (cartMetafields.length == 0) {
      //   const { data: createMetafieldData } = await CartClient.post('/createCartMetaFields', {
      //     cartItems: {
      //       permission_set: 'write_and_sf_access',
      //       key: 'cart-items',
      //       value: JSON.stringify(finalItemList),
      //       namespace: 'cart_info',
      //     },
      //   })
      //   console.log('createMetaField for Cart -- ', createMetafieldData)
      // } else {
      //   const cartItemMetaExist = cartMetafields.find((item) => item.key == 'cart-items')
      //   if (cartItemMetaExist) {
      //     const checkIfSameValue = cartItemMetaExist.value === JSON.stringify(finalItemList)
      //     if (!checkIfSameValue) {
      //       const { data: updateMetafieldData } = await CartClient.post('/updateCartMetafield', {
      //         metaId: cartItemMetaExist.id,
      //         cartItems: {
      //           permission_set: 'write_and_sf_access',
      //           key: 'cart-items',
      //           value: JSON.stringify(finalItemList),
      //           namespace: 'cart_info',
      //         },
      //       })
      //       console.log('updateMetaField for Cart -- ', updateMetafieldData)
      //     }
      //   }
      // }
      
      const result = await getCartCheckoutRedirectUrl()
      if (isAuthenticated) {
        let getLoginToken = JSON.parse(
          JSON.parse(localStorage.getItem('persist:gore-na')).userReducer
        ).access_token
        Cookies.set('gore_auth', true, { domain: '.gorewear.com', secure: false })
        Cookies.set('auth_token', getLoginToken, { domain: '.gorewear.com', secure: false })
      } else {
        Cookies.set('gore_auth', false, { domain: '.gorewear.com', secure: false })
      }
      if (result && result.returnUrl) {
        router.push(result.returnUrl)
      }
    } catch (error) {
      let em = []
      if (error.response && error.response.data) {
        em.push(error.response.data.message)
      } else {
        em.push(error.message)
      }
      addToast(<ErrorItemsNode errors={em} />, { appearance: 'error' })
    }
  }

  const setCountry = async (e, item) => {
    let newItem = selectShippingRegions(item)
    setSelectedCountry(newItem)
    setCartLocale(newItem)
    setOpenCountrySelect(false)
  }

  const showCountrySelect = () => {
    if (region.region !== 'US') {
      setOpenCountrySelect(!openCountrySelect)
    }
  }

  useEffect(() => {
    const newShippingPrice = getShippingPrice(base_amount)

    console.log('the all cart items', all_cart_items, cart_amount)

    setShippingPrice(newShippingPrice)
  }, [cartId, base_amount])

  return (
    <>
      <div className="cart-view flex flex-col relative h-screen overflow-hidden bg-gray-900">
        <div className="cart-view__header flex justify-between items-center border-b border-gray-400">
          <h3 className="cart-view__header-title font-primary text-white uppercase">
            {trans('your-cart', 'Your cart')}
          </h3>
          <button type="button" className="outline-none focus:outline-none">
            <Icon
              className={`cursor-pointer text-white fill-current`}
              viewBox={`0 0 17 17`}
              size={16}
              icon="close"
              onClick={async () => await dispatch(updateCartModal(false))}
            />
          </button>
        </div>
        <div className="cart-view__content flex-1 text-white">
          <SpinnerComponent fullHeight={true} show={cart_loading}>
            {all_cart_items.length === 0 && (
              <div className="cart-view__items--empty text-center">
                <div className="cart-view__items--empty-text">
                  <p>
                    {' '}
                    {trans('empty-cart-text-1', 'You’ve not added anything to your cart yet.')}{' '}
                  </p>
                  <p>
                    {' '}
                    {trans(
                      'empty-cart-text-2',
                      'If you’ve got an account, login to see items you previously added and your Wish List.'
                    )}
                  </p>
                </div>
                <div className="cart-view__items--empty-btn-wrap">
                  <button
                    onClick={async () => await dispatch(updateCartModal(false))}
                    className="btn-lg uppercase bg-gray-400 hover:bg-gray-500 text-primaryA rounded-full w-full"
                  >
                    {trans('continue-shopping', 'Continue Shopping')}
                  </button>

                  {!isAuthenticated && (
                    <div className="cart-view__items--empty-login-btn inline-flex items-center uppercase mx-auto">
                      <div className="border-b border-accent" style={{ marginRight: '12px' }}>
                        <SignInLink
                          linkClass="hover:text-gray-400 "
                          text={trans('login', 'login')}
                        />
                      </div>
                      <Icon
                        style={{ width: '16px' }}
                        className={`cursor-pointer text-accent fill-current`}
                        viewBox={`0 0 24 24`}
                        size={24}
                        icon="arrow-right"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {all_cart_items.length > 0 && (
              <div className="cart h-full flex flex-col justify-between">
                <div className="cart__items flex flex-col overflow-auto flex-grow-0">
                  {all_cart_items.map((item, index) => {
                    if (item.added_to_wishlist) {
                      return (
                        <div
                          key={index}
                          className={`cart__item-moved-wishlist flex items-center justify-center`}
                        >
                          <div className="cart__item-moved-wishlist-content border-b border-gray-400 w-full flex justify-center">
                            <p>
                              {item.name} {trans('was-moved-your', 'was moved to your')}{' '}
                              <Link
                                href="/account/wishlist"
                                onClick={async () => await dispatch(updateCartModal(false))}
                                className="text-accent"
                              >
                                {trans('wish-list', 'Wish List')}
                              </Link>
                            </p>
                          </div>
                        </div>
                      )
                    }
                    return <CartItem key={index} item={item} setLoading={setLoading} />
                  })}
                </div>
                <div className="cart__total bg-gray-300 text-primaryA absolute bottom-0 w-full">
                  <div className="cart__total-text-subtotal flex justify-between font-primary font-light">
                    <span>{trans('subtotal', 'Subtotal')}</span>
                    <span className="font-primary font-light">
                      {currencySymbol}
                      {formatPrice(cart_amount)}
                    </span>
                  </div>
                  <div className="cart__total-text-shipping relative flex justify-between border-b border-gray-400">
                    <CountryCurrencySelect
                      show={openCountrySelect}
                      setShow={setOpenCountrySelect}
                      countryList={regions.default}
                      selectedCountry={selectedCountry}
                      setCountry={setCountry}
                      lineItems={line_items}
                      className="country-selector__options absolute flex flex-row z-50 border-t border-gray-700"
                      style={{ bottom: '60px' }}
                    />
                    <div
                      className={`flex items-center ${region?.storeSource == 'US' && 'invisible'}`}
                    >
                      <span>{trans('shipping-to', 'Shipping to:')}</span>
                      <button
                        type="button"
                        className={`flex items-center outline-none focus:outline-none ${
                          region.region == 'US' ? 'cursor-default' : ''
                        }`}
                        onClick={() => showCountrySelect()}
                      >
                        <strong className="pl-1 pr-1">{selectedCountry.countryName}</strong>
                        {region.region != 'US' && (
                          <Icon
                            className={`cursor-pointer text-black fill-current w-5 h-5`}
                            viewBox={`0 0 24 24`}
                            size={24}
                            icon="chevron-down"
                          />
                        )}
                      </button>
                    </div>
                    {shippingPrice != null && cart_amount != 0 && region?.storeSource != 'US' && (
                      <>
                        {shippingPrice == 0 ? (
                          <span className="uppercase">{trans('free', 'free')}</span>
                        ) : (
                          <span className="uppercase">
                            {currencySymbol}
                            {shippingPrice}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div className="cart__total-text-total flex items-center justify-between">
                    <span>{trans('total', 'Total')}</span>
                    {shippingPrice != null && cart_amount != 0 ? (
                      <span className="font-primary font-light">
                        {currencySymbol}
                        {region?.storeSource != 'US'
                          ? formatPrice(cart_amount + shippingPrice)
                          : formatPrice(cart_amount)}
                      </span>
                    ) : (
                      <span className="font-primary font-light">
                        {currencySymbol}
                        {formatPrice(cart_amount)}
                      </span>
                    )}
                  </div>
                  {line_items.physical_items.length > 0 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        openCheckoutPage()
                      }}
                      disabled={
                        !all_items_in_stock && isCommingSoonProductExist && isRetiredProductExist
                      }
                      className={`btn-lg bg-black hover:bg-gray-800 active:bg-gray-700 text-white uppercase w-full rounded-full ${
                        !all_items_in_stock || isCommingSoonProductExist || isRetiredProductExist
                          ? 'disableATC'
                          : ''
                      }`}
                    >
                      {trans('checkout', 'Checkout')}
                    </button>
                  )}

                  {all_cart_items.find((item) => item.added_to_wishlist) && (
                    <Link
                      href="/account/wishlist"
                      onClick={async () => await dispatch(updateCartModal(false))}
                      className={`checkout-link btn border border-secondary text-secondary w-full mb-12`}
                    >
                      {trans('goto-wishlist', 'Go to wish list')}
                    </Link>
                  )}
                </div>
              </div>
            )}
          </SpinnerComponent>
        </div>
      </div>
      <style jsx>{`
        .cart-view {
          width: 100%;
        }

        .cart-view__header {
          padding: 34px 34px 32px 30px;
        }

        .cart-view__header-title {
          font-size: 15px;
          line-height: 15px;
        }

        .cart-view__items--empty {
          padding: 45px 22px;
        }

        .cart-view__items--empty-text {
          padding: 0 14px;
        }

        .cart-view__items--empty-text p {
          font-size: 16px;
          line-height: 20px;
        }

        .cart-view__items--empty-text p:nth-of-type(1) {
          margin-bottom: 18px;
        }

        .cart-view__items--empty-text p:nth-of-type(2) {
          margin-bottom: 45px;
        }

        .cart-view__items--empty button {
          margin-bottom: 40px;
        }

        .cart-view__items--empty-login-btn {
          font-size: 18px;
          line-height: 18px;
        }

        .cart {
          height: calc(100vh - 83px);
          padding-bottom: 251px;
        }

        .cart__item-moved-wishlist {
          font-size: 16px;
          line-height: 20px;
          padding: 0;
        }

        .cart__item-moved-wishlist-content {
          padding: 60px 24px;
        }

        .cart__total {
          height: ${device ? 265 : 290}px;
          padding: 22px 30px 20px;
          z-index: 20;
        }

        @media only screen and (max-width: 767px) {
          .cart__total {
            height: 350px !important;
          }
        }

        .cart__total-text-subtotal,
        .cart__total-text-shipping {
          font-size: 15px;
          line-height: 15px;
          margin-bottom: 22px;
        }

        .cart__total-text-shipping {
          margin-bottom: 31px;
          padding-bottom: 22px;
        }

        .cart__total-text-total {
          margin-bottom: 20px;
        }

        .cart__total-text-total span:nth-of-type(1) {
          font-size: 20px;
          line-height: 18px;
        }

        .cart__total-text-total span:nth-of-type(2) {
          font-size: 24px;
          line-height: 30px;
        }

        @media (min-width: ${styleConfig.theme.screens.md}) {
          .cart-view {
            width: 600px;
          }

          .cart-view__header-title {
            font-size: 18px;
            line-height: 18px;
          }

          .cart__total {
            height: 265px;
          }

          .cart__total-text-subtotal,
          .cart__total-text-shipping {
            font-size: 18px;
          }

          .cart__total-text-total span:nth-of-type(1) {
            font-size: 25px;
            line-height: 23px;
          }

          .cart__total-text-total span:nth-of-type(2) {
            font-size: 32px;
            line-height: 40px;
          }

          .cart-view__items--empty {
            margin: 0 auto;
            max-width: 310px;
            padding: 45px 0;
          }

          .cart-view__items--empty-text {
            padding: 0;
          }

          .cart-view__items--empty-btn-wrap {
            padding: 0 12px;
          }

          .cart__item-moved-wishlist {
            padding: 0 24px;
          }

          .cart__item-moved-wishlist-content {
            padding: 60px 0;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .cart__item-moved-wishlist {
            padding: 0 30px;
          }
        }
      `}</style>
    </>
  )
}

export default CartView
